import classNames from "classnames";
import { User, Userv2 } from "models/user";
import Link from "next/link";
import { useRouter } from "next/router";
import { FocusEventHandler, PropsWithChildren, Ref, useEffect, useRef, useState } from "react";
import Logo from "./Logo";
import { UserAvatar } from "./User";
import NavbarUser from 'components/navbar/User'
import Notifications from "./navbar/Notifications";
import Button from "./Button";
import { PropsWithClass, PropsWithClassAndStyle, PropsWithPrefixClass } from "utils/props";
import { XOR } from "utils/xor";
import { twMerge } from "tailwind-merge";
import { useUser } from "hooks/user";


type NavbarLinkProps = { dynamic?: boolean; href: string; }

const navbarLinkCx = classNames(
  "text-black",
  "font-normal",
  "hover:text-secondary",
  "p-0.5",
  "hover:cursor-pointer",
  "border border-transparent hover:border-secondary pl-0.5 rounded-md hover:cursor-pointer",
  "!no-underline",
)

const navbarSectionContainerCx = classNames(
  "bg-white hidden group-hover/section:flex flex-col py-1 px-0.5 rounded-md shadow-inplace-xl w-15"
)

export function NavbarLink({
  dynamic,
  children,
  href,
  arrow,
  className,
  style,
}: PropsWithClassAndStyle<PropsWithChildren<NavbarLinkProps & { arrow?: boolean }>>) {
  const router = useRouter();
  const element = (
    <a
      className={twMerge(navbarLinkCx, className)}
      style={style}
      key={href}
      href={href}
    >
      {children}
    </a>
  );
  return dynamic ? (
    <Link href={href} passHref>
      {element}
    </Link>
  ) : (
    element
  );
}

export function NavbarSection({ children, name, link }: PropsWithChildren<{ name: string; link?: NavbarLinkProps }>) {
  return <div className="relative group/section"> {/* FIXME: something weird is going on with selecting & pointer cursor on this element. Too bad! */}
    {!!link ? <NavbarLink {...link} arrow={!!children}>{name}</NavbarLink> :
      <h2 className="group-hover/section:text-secondary font-normal max-2xl:text-base text-black hover:cursor-pointer">
        {name}
        {!!children && <img src="/images/arrow-down.svg" className="group-hover/section:rotate-180 transition-transform duration-200 inline-block ml-0.5" width={12} height={12} />}
      </h2>}
    {!!children && <div className="mt-1 pt-1 absolute top-0 left-0 z-40">
      <div className={navbarSectionContainerCx}>
        {children}
      </div>
    </div>}
  </div>
}

export function NavbarLinkGroup({ children, name, link, childrenClassName }: PropsWithChildren<{ name: string; link?: NavbarLinkProps }> & PropsWithPrefixClass<{}, "children">) {
  return <div className={twMerge(navbarLinkCx, "relative group justify-between items-center flex gap-0.5 flex-row w-full")}>
    <span>{name}</span>
    <img src="/images/arrow-down.svg" className="w-[16px] h-[16px] -rotate-90" />
    <span className="left-14 top-0 absolute hidden group-hover:flex">
      <div className={twMerge("ml-0.5", navbarSectionContainerCx, childrenClassName)}>
        {children}
      </div>
    </span>
  </div>
}


function NavbarLinks({ user }: { user: Userv2 | undefined }) {
  return <div className="gap-3 max-2xl:gap-2 max-xl:gap-1 md:flex justify-center items-center hidden">
    <NavbarSection name="Авторам">
      {/* <NavbarLink href="#">Преимущества</NavbarLink> */}
      {/* <NavbarLink href="#">Проверить публикацию</NavbarLink> */}
      {/* <NavbarLink href="#">Стать автором</NavbarLink> */}
      <NavbarLink href="/info/services" dynamic>Преимущества</NavbarLink>
      <NavbarLink href="/intellichecker">Проверить рукопись</NavbarLink>
      {/* <NavbarLink href="#">Рекоммендации по написанию статей</NavbarLink> */}
      <NavbarLinkGroup name="Подготовка статьи к публикации" childrenClassName="w-20">
        <NavbarLink href="https://cardiovascular.elpub.ru/jour/article/view/3501">Научные обзоры</NavbarLink>
        <NavbarLink href="https://cardiovascular.elpub.ru/jour/article/view/3770">Инфографика в статье</NavbarLink>
        <NavbarLink href="https://cardiovascular.elpub.ru/jour/article/view/3909">Создание письменного отчета/статьи</NavbarLink>
        <NavbarLink href="https://cardiovascular.elpub.ru/jour/article/view/3946">Обзор современного состояния проблемы</NavbarLink>
        <NavbarLink href="https://gnicpm.ru/wp-content/uploads/2020/08/e-high-quality_clinical_practice5-3.pdf">Методологические основы клинического исследования</NavbarLink>
      </NavbarLinkGroup>
    </NavbarSection>
    <NavbarSection name="Рецензентам">
      <NavbarLink href="/info/reviewers" dynamic>Стать подтвержденным рецензентом</NavbarLink>
      {!!user && <NavbarLink href={encodeURI('/profile/@me?tab=reviews')} dynamic>Мои рецензии</NavbarLink>}
    </NavbarSection>
    <NavbarSection name="Редакторам">
      <NavbarLink href="/info/editors" dynamic>Стать редактором</NavbarLink>
      {!!user && <NavbarLink href={encodeURI('/journals/@me')} dynamic>Мой журнал</NavbarLink>}
    </NavbarSection>
    <NavbarSection name="Ресурсы">
      <NavbarLink href="http://51.250.66.81:8085/index.php/Заглавная_страница">Справка</NavbarLink>
      <NavbarLink href="/static/guide.pdf">Руководство пользователя</NavbarLink>
      <NavbarLink href="https://prevmed.elpub.ru/">Терапия без границ</NavbarLink>
      <NavbarLink href="https://ropniz.ru/doctors/library">Библиотека профилактической кардиологии</NavbarLink>
      <NavbarLink href="https://ropniz.ru/doctors/events">Программы РОПНИЗ</NavbarLink>
      <NavbarLink href="/courses" dynamic>Курсы, вебинары, обучающие мероприятия</NavbarLink>
      <NavbarLink href="/journals" dynamic>Журналы</NavbarLink>
    </NavbarSection>
  </div>
}



// export function NavbarLinks() {
//   const list: [
//     string, string,
//     [string, string][]
//   ][] = [
//       ["Авторам", "/#", [
//         ['']
//       ]],
//       ["Рецензентам", "/#", []],
//       ["Редакторам", "/#", []],
//       // ["Ресурсы", `/journals/${process.env.NEXT_PUBLIC_TEST_JOURNAL_ID}`],

//       // ["Регистрация", "/#"],
//       ["Ресурсы", "/#", []],
//       // ["Издательствам", "/#"],
//       // ["Research Blog", "/#"],
//     ];

//   return (
//     <div className="gap-3 md:flex justify-center items-center hidden">
//       {list.map(([name, link, sub]) => (
//         <div className="relative group">
//           <NavbarLink href={link} dynamic key={link} name={name} arrow={sub.length > 0} />
//           {sub.length > 0 && <div className="bg-white hidden group-hover:flex absolute mt-0.5 p-1 rounded-md shadow-inplace-xl">
//           </div>}

//         </div>
//       ))}
//     </div>
//   );
// }

export function Navbar({ variant, static: fixed, user, className, style, version = 'v1', showParentLogos }: PropsWithClassAndStyle<XOR<{ variant: 'absolute' | 'fixed' }, { static: boolean }> & { user?: Userv2; version?: 'v1' | 'v2'; showParentLogos?: boolean }>) {
  const router = useRouter()
  return (
    <nav
      className={classNames(
        className,
        "py-0.5 px-3 max-3xl:px-1",
        "flex",
        "items-center",
        {
          "justify-between md:justify-center md:gap-1 lg:justify-between lg:gap-0": version === 'v1',
          // 'justify-center gap-17': version === 'v2'
          // 'justify-between 4xl:px-14': version === 'v2'
          'justify-between 4xl:justify-center 4xl:gap-17': version === 'v2'
          // 'justify-center gap-17': version === 'v2'
        },
        // "border-b-[1px] border-b-light-grey",
        "w-full",
        // "t-0",
        { [!!fixed || !variant ? 'static' : variant]: true }, // TODO: rename fixed to static
      )}
      data-test="navbar"
      style={style}
    >
      <div className="flex flex-row gap-1 items-center relative">
        <Logo width={104} height={95} text layout="fixed" className="h-4 w-auto" />
        {showParentLogos && <>
          <a href="https://gnicpm.ru/" className="h-2 left-8 w-auto absolute" target="_blank" rel="noreferrer"><img src="/images/nmic.png" className="h-2 w-auto" /></a>
          <a href="https://ropniz.ru/" className="left-5 h-2 w-auto absolute" target="_blank" rel="noreferrer"><img src="/images/ropniz.png" className="h-2 w-auto" /></a>
        </>}
      </div>
      <NavbarLinks user={user} />
      <div className="flex flex-row gap-1 items-center">
        {user ? <>
          <Notifications />
          <NavbarUser user={user} />
        </> : <>
          <Button onClick={() => router.push('/register')} colorscheme="light" data-test="register">Регистрация</Button>
          <Button onClick={() => router.push('/login')} data-test="login">Войти</Button>
        </>}
      </div>
    </nav>
  );
}

